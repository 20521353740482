<!-- eslint-disable -->
<template>
  <v-app style="background-color: #E6E5E5">
    <IntroCalendarForm />
    <BackHomeToolbar :backup="unsavedChanges" @backupDone="resetChanges" />

    <v-main
      :class="$vuetify.display.xs? 'pt-0':'pt-8'"
    >
      <v-container fluid class="pa-0 mb-12">

        <!--CALENDAR-->
        <v-row align="center" justify="center" class="pa-0 ma-0" >
          <v-col cols="12" sm="11" align="center" class="px-1 pt-1">
            <v-sheet border :elevation="1">

              <!--CALENDAR TOOLBAR-->
              <v-toolbar flat color="#009688">
                
                <!--FILTER-->
                <CalendarFilter @filter="filter" :refresh="refreshFilter" :currentMonth="calendarMonth" class="mb-6"/>

                <v-toolbar-title>
                  <v-btn
                    variant="text"
                    size="large"
                    icon="mdi-chevron-left"
                    @click="prev()"
                    class="d-none d-sm-inline-flex"
                  >
                  </v-btn>
                  
                  <v-menu transition="slide-y-transition">
                    <template v-slot:activator="{ props }">
                      <v-btn
                        class="text-none text-body-1"
                        v-bind="props"
                      >
                      {{ calendarDateStore.getMonthName(calendarMonth) }}
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item
                        v-for="(month, index) in 12"
                        :key="index"
                      >
                        <v-btn
                          block
                          @click="hideCalendarMonths(month)"
                          class="text-none text-body-1"
                        >
                          {{ calendarDateStore.getMonthName(month) }}
                        </v-btn>
                      </v-list-item>
                    </v-list>
                  </v-menu>

                  <v-btn
                    variant="text"
                    size="large"
                    icon="mdi-chevron-right"
                    @click="next()"
                    class="d-none d-sm-inline-flex"
                  >
                  </v-btn>
                </v-toolbar-title>

                <v-menu transition="slide-y-transition">
                  <template v-slot:activator="{ props }">
                    <v-btn
                      class="text-none text-body-1"
                      v-bind="props"
                      prepend-icon="mdi-calendar-blank"
                      append-icon="mdi-menu-down"
                    >
                      <v-list-item-title v-if="!smAndDown">{{ calendarYear }}</v-list-item-title>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item
                      v-for="(year, index) in usageYears"
                      :key="index"
                    >
                      <v-btn block @click="hideCalendarYears(year)">{{ year }}</v-btn>
                    </v-list-item>
                  </v-list>
                </v-menu>

                <v-btn
                  variant="outlined"
                  class="text-none text-body-1 mr-4"
                  @click="goToToday()"
                >
                  Hoje
                </v-btn>
              </v-toolbar>

              <FullCalendar ref="fullCalendar" :options="calendarOptions" />
              
            </v-sheet>
          </v-col>
        </v-row>

        <!--CATEGORIES AND ACTIVITIES-->
        <v-row align="center" justify="center" class="pa-0 ma-0">
          <v-col cols="12" sm="11" align="center" class="px-1 ma-0">
            <CalendarCategories :reload="reload"/>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <CalendarNoInternetError v-model="error" :text="erroMessage" />
    <!-- <CalendarSynchLoader v-model="synch" /> -->
    <CalendarEventInfo v-model="showEventInfo" :eCode="eventCode" @refresh="refresh" />
    <CalendarSnackbar v-model="calendarSnackbar" :text="snackbarText"/>
  </v-app>
</template>

<script setup>
import { ref, onMounted, reactive, watch, onUnmounted } from 'vue'
import { useDisplay } from 'vuetify'
import { useCalendarDateStore } from '@/stores/CalendarDateStore'
import { useCalendarEventsStore } from '@/stores/CalendarEventsStore'
import { useCalendarCategoriesAndActivitiesStore } from '@/stores/CalendarCategoriesAndActivitiesStore'
import { useCalendarRegisterStore } from '@/stores/CalendarRegisterStore'
import { useLocalStore } from '@/stores/LocalStore'
import { storeToRefs } from 'pinia'
import db from '@/db'
import axios from 'axios'
import { syncAll }  from '@/helpers/sync'
import BackHomeToolbar from '@/components/BackHomeToolbar.vue'
import IntroCalendarForm from '@/components/IntroCalendarForm.vue'
import CalendarFilter from '@/components/CalendarFilter.vue'
import CalendarCategories from '@/components/CalendarCategories.vue'
import CalendarEventInfo from '@/components/CalendarEventInfo.vue'
import CalendarSnackbar from '@/components/CalendarSnackbar.vue'
// import CalendarSynchLoader from '@/components/CalendarSynchLoader.vue'
import CalendarNoInternetError from '@/components/CalendarNoInternetError.vue'
import FullCalendar from '@fullcalendar/vue3'
import dayGridPlugin from '@fullcalendar/daygrid'
import useChangeTracker from '@/helpers/dbWatcher.js';

const api = import.meta.env.VITE_API

const calendarCategoriesAndActivitiesStore = useCalendarCategoriesAndActivitiesStore()
const { usageYears } = storeToRefs(calendarCategoriesAndActivitiesStore)

const calendarRegisterStore = useCalendarRegisterStore()

const localStore = useLocalStore()
const { currentUser, synch } = storeToRefs(localStore)

const calendarEventsStore = useCalendarEventsStore()
const { events } = storeToRefs(calendarEventsStore)

const calendarDateStore = useCalendarDateStore()
const { calendarYear, calendarMonth } = storeToRefs(calendarDateStore)

const { name, smAndDown } = useDisplay()

const { unsavedChanges, resetChanges } = useChangeTracker()
const lastBackup = ref(null)
const lastChange = ref(null)
const reload = ref(false)

const fullCalendar = ref(null)

const calendarSnackbar = ref(false)
const snackbarText = ref('')
const erroMessage = ref('')
const error = ref(false)

const showEventInfo = ref(false)
const eventCode = ref(null)

const refreshFilter = ref(null)

const DAY_NAMES_LONG = ['Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado', 'Domingo']
const DAY_NAMES_SHORT = ['S', 'T', 'Q', 'Q', 'S', 'S', 'D']

const calendarOptions = reactive({
  plugins: [dayGridPlugin],
  headerToolbar: false,
  initialView: 'dayGridMonth',
  locale: 'pt',
  fixedWeekCount: false,
  dayHeaderContent: function (arg) {
    if (smAndDown.value) {
      return DAY_NAMES_SHORT[arg.date.getDay()]
    }
    return DAY_NAMES_LONG[arg.date.getDay()]
  },
  showNonCurrentDates: false,
  events: events,
  eventClick: function (info) {
    const eventObj = info.event
    
    eventCode.value = eventObj._def.publicId
    showEventInfo.value = true
    snackbarText.value = 'Evento: ' + eventObj.title
    calendarSnackbar.value = true
  },
  dayMaxEvents: true
})

onMounted(async () => {
  await sync()
  useChangeTracker()
  lastBackup.value = JSON.parse(localStorage.getItem('lastBackup'))
  lastChange.value = JSON.parse(localStorage.getItem('lastChange'))
  usageYears.value.clear()
  setCalendarHeight()
  await calendarRegisterStore.loadData()
  await calendarEventsStore.getEvents()

  getNotificatonsPermission()
})

const checkOnline = () => {
  if (!navigator.onLine) {
    erroMessage.value = 'É necessário uma conexão com a internet para prosseguir! Por favor, verifique suas configurações de rede ou tente novamente mais tarde.'
    error.value = true
    return false
  }
  return true
}

const openSnackbar = (message) => {
  snackbarText.value = message
  calendarSnackbar.value = true
}

watch(synch, async () => {
  if (synch.value) {
    await sync()
    refresh()
  }
})

watch(calendarMonth, () => {
  calendarEventsStore.getEvents()
})

watch(smAndDown, () => {
  renderCalendar()
})

watch(name, () => {
  setCalendarHeight()
})

watch(calendarYear, () => {
  loadCalendarOfYear()
})

const refresh = () => {
  calendarEventsStore.getEvents()
  reload.value = !reload.value
}

const renderCalendar = () => {
  if (fullCalendar.value) {
    const calendarApi = fullCalendar.value.getApi()
    calendarApi.render()
  }
}

const setCalendarHeight = () => {
  if (fullCalendar.value) {
    const calendarApi = fullCalendar.value.getApi()

    switch (name.value) {
      case 'xs':
        calendarApi.setOption('height', 500)
        break
      case 'sm':
        calendarApi.setOption('height', 600)
        break
      default:
        calendarApi.setOption('height', 800)
        break
    }
  }
}

const prev = () => {
  if (fullCalendar.value) {
    const calendarApi = fullCalendar.value.getApi()

    if (calendarMonth.value !== 1) {
      calendarMonth.value--
      calendarApi.prev()
    } else {
      calendarMonth.value = 12
      calendarYear.value--
      calendarApi.prev()
    }
  }
}

const next = () => {
  if (fullCalendar.value) {
    const calendarApi = fullCalendar.value.getApi()

    if (calendarMonth.value !== 12) {
      calendarMonth.value++
      calendarApi.next()
    } else {
      calendarMonth.value = 1
      calendarYear.value++
      calendarApi.next()
    }
  }
}

const goToToday = () => {
  if (fullCalendar.value) {
    const calendarApi = fullCalendar.value.getApi()
    calendarApi.today()
    calendarDateStore.$reset()
  }
}

const goToMonth = (selectedMonth) => {
  if (fullCalendar.value) {
    calendarMonth.value = selectedMonth
    const calendarApi = fullCalendar.value.getApi()
    calendarApi.gotoDate(new Date(parseInt(calendarYear.value), selectedMonth - 1, 1))
  }
}

const hideCalendarYears = (selectedYear) => {
  const currentYear = new Date().getFullYear();
  
  if (Number(selectedYear) === currentYear) {
    // Reset to the current year and month
    calendarYear.value = currentYear;
    calendarMonth.value = new Date().getMonth() + 1; // Adjust to current month
  } else {
    // Set the selected year and reset the month to January
    calendarYear.value = selectedYear;
    calendarMonth.value = 1; // January
  }
}

const hideCalendarMonths = (selectedMonth) => {
  goToMonth(selectedMonth)
}

const loadCalendarOfYear = async () => {
  await calendarCategoriesAndActivitiesStore.fetchData()
  // calendarEventsStore.getEvents()
  console.log('Calendar of year ' + calendarYear.value + ' loaded!')
}

const filter = () => {
  calendarEventsStore.getEvents()
}

async function sync() {
  checkOnline()

  const now = Date.now()
  const lastSynchTimestamp = JSON.parse(localStorage.getItem('synchronized'))
  const lastSynchDate = new Date(lastSynchTimestamp)
  
  console.log('Starting sync process. Checking server status.')
  
  try {
    await axios.get(`${api}/status`, { timeout: 2000 })
    console.log('Server OK. Loading events from local DB.')

    await syncAll(lastSynchTimestamp, lastSynchDate)

    localStorage.setItem('synchronized', now)
    
    // Sleep for a moment to observe the animation
    await new Promise(resolve => setTimeout(resolve, 2000));

    synch.value = false
    openSnackbar('Dados sincronizados com sucesso')

  } catch (error) {
    console.log(error)
  }
}

// Handle unsaved changes when the user attempts to leave the page
// window.addEventListener('beforeunload', (event) => {
//   if (unsavedChanges.value) {
//     event.preventDefault()
//   }
// })

const getNotificatonsPermission = () => {
  if ('Notification' in window && 'serviceWorker' in navigator) {
    // Request permission for notifications
    Notification.requestPermission().then((permission) => {
      if (permission === 'granted') {
        console.log('Permissão para notificações concedida!')
        subscribeUserToPush()
      } else {
        console.log('Notificações bloqueadas pelo usuário.')
      }
    }).catch((error) => {
      console.error('Erro ao solicitar permissão para notificações:', error)
    })
  }
}

const subscribeUserToPush = async () => {
  try {
    const registration = await navigator.serviceWorker.ready

    const subscription = await registration.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: import.meta.env.VITE_VAPID_PUBLIC_KEY
    })

    // Send the subscription object to your backend
    await axios.post(`${api}/remote/subscribe`, subscription)

  } catch (error) {
    console.error('Erro ao criar inscrição ou enviar para o backend:', error)
  }
}

onUnmounted(() => {
  window.removeEventListener('beforeunload', (event) => {
    if (unsavedChanges.value) {
      event.preventDefault()
    }
  })
})

</script>
