<template>
  <div>
    <v-row v-if="!xs" align="center" justify="start" class="pa-0 ma-0">
      <v-col align="start" class="d-flex align-center pa-0 ma-0">
        <v-btn
          size="x-large"
          class="px-0 white--text"
          @click.stop="showWizard"
          append-icon="mdi-menu-down"
        >
          <div>
            <v-list-item-title class="text-h6">
              <v-icon size="small" color="white"> mdi-map </v-icon>
              {{ title }}
            </v-list-item-title>
            <v-list-item-title v-if="subtitle !== ''" class="text-subtitle-2">
              <v-icon color="white" class="mb-1"> mdi-map-marker </v-icon>
              {{ subtitle }}
            </v-list-item-title>
          </div>
        </v-btn>
      </v-col>
    </v-row>

    <v-row v-if="xs" align="center" justify="center" class="pa-0 ma-0">
      <v-col cols="11" align="start" class="d-flex align-center pa-0 ma-0">
        <v-btn
          block
          variant="outlined"
          append-icon="mdi-menu-down"
          size="x-large"
          class="my-2 mr-0 px-4"
          @click.stop="showWizard"
        >
          <div>
            <v-list-item-title class="text-h6">
              <v-icon size="small" color="teal"> mdi-map </v-icon>
              {{ title }}
            </v-list-item-title>
            <v-list-item-title v-if="subtitle !== ''" class="text-subtitle-2">
              <v-icon color="error" class="mb-1"> mdi-map-marker </v-icon>
              {{ subtitle }}
            </v-list-item-title>
          </div>
        </v-btn>
      </v-col>
    </v-row>

    <v-dialog
      v-model="wizard"
      width="500px"
      :fullscreen="$vuetify.display.xsOnly"
      persistent
    >
      <CalendarWizard @close="hideWizard" />
    </v-dialog>
  </div>
</template>

<script setup>
import { useDisplay } from "vuetify";
import { ref, onBeforeMount } from "vue";
import { useCalendarNameStore } from "@/stores/CalendarNameStore";
import { storeToRefs } from "pinia";
import CalendarWizard from "@/components/CalendarWizard.vue";

const calendarNameStore = useCalendarNameStore();
const { title, subtitle } = storeToRefs(calendarNameStore);

const { xs } = useDisplay();
const wizard = ref(false);

const showWizard = () => {
  wizard.value = true;
};

const hideWizard = () => {
  wizard.value = false;
};

onBeforeMount(() => {
  calendarNameStore.loadName();
});
</script>
