import { defineStore } from 'pinia'

export const useCategoriesColorStore = defineStore('categoriesColor', {
  state: () => ({
    usedColors: new Set()
  }),
  actions: {
    // Convert RGB to Hex
    rgbToHex (r, g, b) {
      return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase()
    },

    // Convert Hex to RGB
    hexToRgb (hex) {
      hex = hex.replace('#', '')
      if (hex.length !== 6) {
        throw new Error('Invalid hex color code')
      }
      const r = parseInt(hex.substr(0, 2), 16)
      const g = parseInt(hex.substr(2, 2), 16)
      const b = parseInt(hex.substr(4, 2), 16)
      return [r, g, b]
    },

    // Change color tone based on factor
    changeColorTone (hex, factor) {
      let [r, g, b] = this.hexToRgb(hex)
      r = Math.min(255, Math.max(0, r + factor))
      g = Math.min(255, Math.max(0, g + factor))
      b = Math.min(255, Math.max(0, b + factor))
      return this.rgbToHex(r, g, b)
    },

    // Get color base based on category branch
    getColorBase (branch) {
      if (branch === 'vegetal') {
        return [0, 255, 0] // Green
      } else if (branch === 'animal') {
        return [255, 0, 0] // Red
      } else {
        return [0, 0, 255] // Blue (default for other branches)
      }
    },

    // Convert RGB to HSL
    rgbToHsl (r, g, b) {
      r /= 255
      g /= 255
      b /= 255
      const max = Math.max(r, g, b)
      const min = Math.min(r, g, b)
      let h, s, l = (max + min) / 2

      if (max === min) {
        h = s = 0 // achromatic
      } else {
        const d = max - min
        s = l > 0.5 ? d / (2 - max - min) : d / (max + min)
        switch (max) {
          case r: h = (g - b) / d + (g < b ? 6 : 0); break
          case g: h = (b - r) / d + 2; break
          case b: h = (r - g) / d + 4; break
        }
        h /= 6
      }
      return [h, s, l]
    },

    // Convert HSL back to RGB
    hslToRgb (h, s, l) {
      let r, g, b

      if (s === 0) {
        r = g = b = l // achromatic
      } else {
        const q = l < 0.5 ? l * (1 + s) : l + s - l * s
        const p = 2 * l - q
        r = this.hueToRgb(p, q, h + 1 / 3)
        g = this.hueToRgb(p, q, h)
        b = this.hueToRgb(p, q, h - 1 / 3)
      }

      return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)]
    },

    // Helper function to convert hue
    hueToRgb (p, q, t) {
      if (t < 0) t += 1
      if (t > 1) t -= 1
      if (t < 1 / 6) return p + (q - p) * 6 * t
      if (t < 1 / 2) return q
      if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6
      return p
    },

    // Get a unique category color
    getCategoryColor (colorBase, maxAttempts = 50) {
      const hueShift = 15 // The degree to shift hue
      let attempts = 0

      const usedColorsArray = Array.from(this.usedColors) // Convert Set to Array for easy iteration

      const shiftHue = (r, g, b, degrees) => {
        const [h, s, l] = this.rgbToHsl(r, g, b);
        const newHue = ((h * 360 + degrees) % 360) / 360; // Ensure hue stays in range [0, 1]
        return this.hslToRgb(newHue, s, l);
      }      

      while (attempts < maxAttempts) {
        const [r, g, b] = shiftHue(colorBase[0], colorBase[1], colorBase[2], hueShift * attempts)
        const newHexColor = this.rgbToHex(r, g, b).toUpperCase() // Convert to hex and uppercase

        // Check if the new hex color is already used
        if (!usedColorsArray.includes(newHexColor)) {
          this.usedColors.add(newHexColor)
          return newHexColor
        }

        attempts++
      }

      // Fallback if max attempts are reached without finding a unique color
      return this.rgbToHex(colorBase[0], colorBase[1], colorBase[2]) // Return the original color
    }
  }
})
